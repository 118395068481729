<template>
    <div v-if="profile">
      <b-card :title="'Тренутно подешавање таблета ученика из вашег одељења'">
        <b-row>
          <b-col cols="3" md="2" lg="1" xl="1" ><span class="font-medium">Одељење:</span></b-col>
          <b-col cols="6">{{profile.name}}</b-col>
        </b-row>
        
        <!-- Notifications -->

        <b-row style="margin-top: 5px;">
          <b-col cols="12" md="10" lg="9" xl="7">
            <div class="separator" id="profile-notifications">
              Обавештења
              <b-button variant="flat-danger" class="btn-icon" size="sm" v-b-tooltip.hover.v-danger title="Само администратори могу да мењају статус обавештења">
                <feather-icon icon="LockIcon" />
              </b-button>  
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Батерија:</span></b-col>
          <b-col cols="6" md="7" lg="7" xl="5">Обавештење када се ниво батерије уређаја спусти испод 5%</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;"><b-badge variant="success">Активно</b-badge></b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Меморија:</span></b-col>
          <b-col cols="6" md="7" lg="7" xl="5">Обавештење када се ниво меморије уређаја спусти испод 10%</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;"><b-badge variant="success">Активно</b-badge></b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">СИМ:</span></b-col>
          <b-col cols="6" md="7" lg="7" xl="5">Обавештење када се промени СИМ картица уређаја</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;"><b-badge variant="success">Активно</b-badge></b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Стање:</span></b-col>
          <b-col cols="6" md="7" lg="7" xl="5">Обавештење када се уређај укључи/искључи</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;"><b-badge variant="success">Активно</b-badge></b-col>
        </b-row>

        <!-- Device blocked times -->

        <b-row style="margin-top: 10px !important;">
          <b-col cols="12" md="10" lg="9" xl="7">
            <div class="separator" id="profile-block">
              Забрана коришћења
              <b-button v-if="currentBreakPoint != 'xs' && currentBreakPoint != 'sm'" variant="flat-danger" class="btn-icon" size="sm" v-b-tooltip.hover.v-danger title="Само администратори могу да мењају време забране коришћења.">
                <feather-icon icon="LockIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Почетак:</span></b-col>
          <b-col cols="3" md="3" lg="2" xl="1" class="d-flex align-items-center">22:00:00</b-col>
          <b-col cols="6" md="7" lg="6" xl="5">У овом тренутку све функције уређаја постају недоступне</b-col>
        </b-row>
        <b-row style="margin-top: 5px;">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Крај:</span></b-col>
          <b-col cols="3" md="3" lg="2" xl="1" class="d-flex align-items-center">06:00:00</b-col>
          <b-col cols="6" md="7" lg="6" xl="5">У овом тренутку све функције уређаја постају поново доступне</b-col>
        </b-row>

        <!-- Whitelisted urls -->

        <b-row style="margin-top: 10px;">
          <b-col cols="12" md="10" lg="9" xl="7">
            <div class="separator" id="profile-web">
              Дозвољене интернет адресе
              <b-button variant="flat-primary" class="btn-icon" size="sm" v-b-tooltip.hover.v-primary title="Подешавање интернет страница" @click="goToWeb">
                <feather-icon icon="SettingsIcon" />
              </b-button>  
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 5px;" v-for="(item, index) in profile.whitelistedUrls" :key="'w-' + index">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Назив:</span></b-col>
          <b-col cols="5">{{item.name}}</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;"><b-badge variant="success">Дозвољено</b-badge></b-col>
        </b-row>

        <!-- Controlled applications -->

        <b-row style="margin-top: 10px;">
          <b-col cols="12" md="10" lg="9" xl="7">
            <div class="separator" id="profile-apps">
              Контролисане апликације
              <b-button variant="flat-primary" class="btn-icon" size="sm" v-b-tooltip.hover.v-primary title="Подешавање апликација" @click="goToApplications">
                <feather-icon icon="SettingsIcon" />
              </b-button>  
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 5px;" v-for="(item, index) in profile.controlledApplications" :key="'a-' + index">
          <b-col cols="3" md="2" lg="1" xl="1" class="d-flex align-items-center"><span class="font-medium">Назив:</span></b-col>
          <b-col cols="5">{{item.name}}</b-col>
          <b-col cols="2" md="3" lg="3" xl="3" class="d-flex align-items-center" style="padding-left: 0px !important;">
            <b-badge v-if="item.status == 'installed'" variant="success">Инсталирана</b-badge>
            <b-badge v-if="item.status !== 'installed'" variant="danger">Уклоњена</b-badge>
          </b-col>
        </b-row>

      </b-card>


      <b-modal id="modal-change" cancel-variant="secondary" cancel-title="Одустани" ok-title="Сачувај" centered size="s" title="Подешавање забране коришћења" @ok="setProfileBlock">
        <b-card-text>
          <b-card-text>
            Молимо вас унесите нове вредности за време почетка и краја забране коришћења
          </b-card-text>
          <b-row>
            <b-col cols="6" style="text-align: center;" class="font-medium">
              <span>Почетак забране</span>
              <b-time v-model="blocked_from" locale="sr"></b-time>
            </b-col>
            <b-col cols="6" style="text-align: center;" class="font-medium">
              <span>Крај забране</span>
              <b-time v-model="blocked_to" locale="sr"></b-time>
            </b-col>
          </b-row>
        </b-card-text>
      </b-modal>


    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BCard, BRow, BCol, BBadge, BButton, VBTooltip, BTime, BModal, VBModal, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BTime,
    BModal,
    VBModal,
    BCardText
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  mounted() {
    this.$store.dispatch('profile/getProfile', {toast: this.$bvToast});
  },
  data() {
    return {
      blocked_from: null,
      blocked_to: null
    }
  },
  computed: {
    profile() {
      return this.$store.getters['profile/profile'];
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint'];
    }
  },
  methods: {
    goToWeb() {
      this.$router.push({ path: '/web' });
    },
    goToApplications() {
      this.$router.push({ path: '/applications' });
    },
    setBlocks() {
      this.blocked_from = this.profile.device_blocked_from;
      this.blocked_to = this.profile.device_blocked_to;
    },
    setProfileBlock() {
      this.$store.dispatch('profile/setProfileBlock', {blocks: {from: this.blocked_from, to: this.blocked_to}, toast: this.$bvToast});
    }
  },
  destroyed() {
    this.$store.commit('profile/RESET');
  }
}
</script>

<style lang="scss" scoped>
  
</style>
