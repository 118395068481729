import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  devices: []
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    devices: state => {
      return state.devices;
    },
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_DEVICES(state, value) {
      state.devices = value.devices;
    },
  },
  actions: {
    getAllDevices({rootState, commit}, payload) {
      axios.get(baseUrl + 'device/getAllDevices/' + rootState.auth.device_group, rootState.auth.token).then(
        res => {
          commit('SET_DEVICES', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
  },
}
