<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Унесите ваше питање испод
          </h2>
          <b-card-text class="mb-2">
            или одаберите категорију да брзо пронађете одговор који вам је потребан
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input" v-on:submit.prevent>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Претражи питања и одговоре..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Имате још питања?</h2>
          <b-card-text class="mb-3">
            Уколико не можете да пронађете одговор на овој страници, увек можете да нас контактирате. Одговорићемо у најкраћем могућем року!
          </b-card-text>
        </b-col>
        <b-col cols="12" md="2" lg="3" xl="3"></b-col>
        <b-col cols="12" md="8" lg="6" xl="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2 avatar-responsive"
            >
              <feather-icon
                icon="MailIcon"
                size="22"
                class="icon-responsive"
                @click="mailtoSupport()"
              />
            </b-avatar>
            <h4><a href="mailto:podrska@beotablet.rs">podrska@beotablet.rs</a></h4>
            <span class="text-body">Најбољи начин да добијете брз одговор!</span>
          </b-card>
        </b-col>
        <b-col cols="12" md="2" lg="3" xl="3"></b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import { BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar } from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      allData: {
          payment: {
            icon: 'CpuIcon',
            title: 'Повезивање',
            subtitle: 'Како повезати таблет са БеоТаблет системом?',
            qandA: [
                {
                    question: 'Шта представља QR код?',
                    altQuestion: 'Sta predstavlja QR kod?',
                    ans: 'QR код представља једноставан начин повезивања таблет уређаја ученика са одељењем ученика. У оквиру QR кода су сачуване информације неопходне за успешно повезивање сваког таблета са БеоТаблет системом и са одељењем којем ученик припада. Скенирање QR кода представља једноставну алтернативу ручном уношењу свих неопходних података.'
                },
                {
                    question: 'Да ли је QR код уникатан?',
                    altQuestion: 'Da li je QR kod unikatan?',
                    ans: 'Да, на нивоу једног одељења. QR код се генерише на нивоу појединачног одељења и уникатан је за свако одељење у свакој школи у оквиру БеоТаблет система. Сви таблети ученика из једног одељења скенирају исти QR код.'
                },
                {
                    question: 'Да ли је могуће одштампати QR код?',
                    altQuestion: 'Da li je moguce odstampati QR kod?',
                    ans: 'Да. Кликом на дугме "Штампај" у оквиру странице за повезивање ће се отворити дијалог путем којег може да се одштампа QR са упутствима.'
                },
                {
                    question: 'Који су кораци за повезивање таблет уређаја са одељењем?',
                    altQuestion: 'Koji su koraci za povezivanje tablet uredjaja sa razredom?',
                    ans: '- Преузимање основног програма таблета \n - Стартовање таблета, након чега ће се појавити БеоТаблет апликација и затражити повезивање уређаја са одељењем \n - Активираће се камера и након тога је потребно скенирати QR код који се налази изнад \n - Након скенирања, таблет ће се повезати са вашим одељењем и систем ће применити заштитне полисе и извршити инсталацију потребних апликација'
                },
                {
                    question: 'Шта је потребно урадити након повезивања?',
                    altQuestion: 'Sta je potrebno uraditi nakon poveyivanja?',
                    ans: 'Ништа. Уколико је таблет успешно повезан, систем ће аутоматски применити заштитне полисе и извршити инсталацију неопходних инсталација.'
                },
                {
                    question: 'Да ли је могуће повезати таблет уређај са више одељења?',
                    altQuestion: 'Da li je moguce povezati tablet uredjaj sa vise razreda?',
                    ans: 'Не. У сваком тренутку таблет мора бити повезан само са једним одељењем.'
                },
                {
                    question: 'Да ли је могуће променити одељење са којим је таблет уређај повезан?',
                    altQuestion: 'Da li je moguce promeniti razred sa kojim je tablet uredjaj povezan?',
                    ans: 'Не. Уколико имате потребу да промените одељење са којим је повезан одређени таблет уређај, молимо вас контактирајте нашу техничку подршку.'
                },
            ],
          },
          delivery: {
            icon: 'TabletIcon',
            title: 'Уређаји',
            subtitle: 'Како проверити стање таблет уређаја у одељењу?',
            qandA: [
                {
                    question: 'Шта представља код уређаја?',
                    altQuestion: 'Sta predstavlja kod uredjaja?',
                    ans: 'Код уређаја представља јединствени идентификатор за распознавање уређаја. Када на уређају кликнете на зупчаник икону у горњем десном углу, отвориће се главни екран БеоТаблет апликације и на њему можете видети код конкретног уређаја.'
                },
                {
                    question: 'Како видети детаље уређаја?',
                    altQuestion: 'Kako videti detalje uredjaja?',
                    ans: 'На страници "Уређаји", у оквиру табеле са уређајима се налази колона "ДЕТАЉИ". Кликом на дугме у овој колони за одређени уређај бићете пребачени на страницу са детаљима за тај уређај (листа и статус свих инсталираних апликација и листа обавештења за тај уређај).'
                },
                {
                    question: 'Да ли је могуће уклонити уређај из одељења?',
                    altQuestion: 'Da li je moguce ukloniti uredjaj iz razreda?',
                    ans: 'Не. Уколико желите да уклоните неки уређај из одељења (можда чак и да га пребаците у друго одељење) контактирајте нашу подршку и пошаљите код уређаја од интереса.'
                }, 
                {
                    question: 'Како функционише филтрирање уређаја?',
                    altQuestion: 'Kako funkcionise filtriranje uredjaja?',
                    ans: 'Филтрирање служи за једноставно проналажење уређаја од интереса уколико постоји велик број уређаја. Након сваког унесеног карактера, табела уређаја се филтрира и приказују се само уређаји који садрже тај карактер.'
                },
                {
                    question: 'Шта представљају обавештења на уређају?',
                    altQuestion: 'Sta predstavljaju obavestenja na uredjaju?',
                    ans: 'Обавештења представљају догађаје од сигурносног значаја који не нарушавају приватност корисника. Више су намењена администраторима система, али и учитељски налози могу да провере обавештења уколико имају потребу за тим.'
                }
            ],
          },
          cancellationReturn: {
            icon: 'AirplayIcon',
            title: 'Апликације',
            subtitle: 'Како се управља апликацијама у оквиру БеоТаблет система?',
            qandA: [
                {
                    question: 'Како инсталирати нову апликацију на таблет уређаје?',
                    altQuestion: 'Kako instalirati novu aplikaciju na tablet uredjaje?',
                    ans: 'У оквиру странице "Апликације" постоји листа свих доступних апликација које могу бити инсталиране. Кликом на неку од њих ће вам се отворити дијалог у ком треба да потврдите инсталацију. Након тога систем аутоматски инсталира ту апликацију на све таблет уређаје у оквиру вашег одељења.'
                },
                {
                    question: 'Како уклонити апликацију са таблет уређаја?',
                    altQuestion: 'Kako ukloniti aplikaciju sa tablet uredjaja?',
                    ans: 'За уклањање важи исти принцип као и за инсталацију. Кораци су исти, само на крају систем уклони одабрану апликацију са уређаја уместо да је инсталира.'
                },
                {
                    question: 'Зашто постоји забрана инсталирања/уклањања апликације?',
                    altQuestion: 'Zasto postoji zabrana instaliranja/uklanjanja aplikacije?',
                    ans: 'Након инсталирања/уклањања неке апликације, појавиће се забрана измене за ту апликацију у трајању од 15 минута. Овај менханизам постоји да би се таблетима омогућило довољно времена да изврше одређену акцију пре него што се покуша следећа.'
                },
                {
                    question: 'Да ли је могуће инсталирати апликацију која није понуђена у оквиру ове странице?',
                    altQuestion: 'Da li je moguce instalirati aplikaciju koja nije ponudjena u okviru ove stranice?',
                    ans: 'Да, али је пре тога неопходно контактирати нашу подршку са захтевом да се жељена апликација дода у листу понуђених апликација. Након тога може да се инсталира као и свака друга апликација из листе.'
                },
                {
                    question: 'Да ли се апликације аутоматски ажурирају?',
                    altQuestion: 'Da li se aplikacije automatski azuriraju?',
                    ans: 'Да. Чим се појави нова верзија апликације, систем ће аутоматски ажурирати апликацију на свим таблетима на којима се налази.'
                },
                {
                    question: 'Да ли постоји начин да ученици сами инсталирају или уклоне апликацију?',
                    altQuestion: 'Da li postoji nacin da ucenici sami instaliraju ili uklone apliikaciju?',
                    ans: 'Не. Једини начин да се апликације инсталирају или уклоне је путем БеоТаблет портала.'
                }
            ],
          },
          myOrders: {
            icon: 'UserIcon',
            title: 'Профил',
            subtitle: 'Шта је профил и како направити измене?',
            qandA: [
                {
                    question: 'Шта представља профил уређаја?',
                    altQuestion: 'Sta predstavlja profil uredjaja?',
                    ans: 'Профил уређаја представља целокупну конфигурацију таблет уређаја у оквиру неког одељења. У оквиру профила се налазе обавештења, забрана коришћења, дозвољене интернет адресе и листа контролисаних апликација.'
                },
                {
                    question: 'Да ли је могуће управљати обавештењима?',
                    altQuestion: 'Da li je moguce upravljati obavestenjima?',
                    ans: 'Не. Само администратори система могу да мењају статус обавештења.'
                },
                {
                    question: 'На који начин функционише забрана коришћења?',
                    altQuestion: 'Na koji nacin funkcionise zabrana koriscenja?',
                    ans: 'Забрана коришћења дефинише временски период у ком је немогуће користити таблет уређај. Када се достигне период дана када почиње забрана, све функционалности таблета се закључавају и остају у том стању док се не достигне период за откључавање.'
                },
                {
                    question: 'Да ли ученици могу да искључе забрану?',
                    altQuestion: 'Da li ucenici mogu da iskljuce zabranu?',
                    ans: 'Не. Не постоји начин да се забрана коришћења онемогући све док је таблет под контролом БеоТаблет система.'
                },
                {
                    question: 'Шта представља секција са интернет адресама?',
                    altQuestion: 'Sta predstavlja sekcija sa internet adresama?',
                    ans: 'Једноставан преглед дозвољених интернет адреса на таблетима у оквиру вашег одељења. Поједностављени облик "Интернет" странице.'
                },
                {
                    question: 'Шта представља секција са апликацијама?',
                    altQuestion: 'Sta predstavlja sekcija sa aplikacijama?',
                    ans: 'Једноставан преглед контролисаних апликација и њиховог статуса на таблетима у оквиру вашег одељења. Поједностављени облик странице "Апликације".'
                },
            ],
          },
          productServices: {
            icon: 'GlobeIcon',
            title: 'Интернет',
            subtitle: 'Како управљати дозвољеним интернет адресама?',
            qandA: [
                {
                    question: 'Шта је BeoTablet Browser?',
                    altQuestion: 'Sta je BeoTablet Browser?',
                    ans: 'Поред основне БеоТаблет апликације на сваком таблет уређају се налази и BeoTablet Browser апликација. Ова апликација је безбедни интернет претраживач који омогућава да ученици имају приступ само адресама из листе дозвољених интернет адреса дефинисаних путем БеоТаблет портала.',
                },
                {
                    question: 'Да ли ученици могу да користе неки други претраживач?',
                    altQuestion: 'Da li ucenici mogu da koriste neki drugi pretrazivac?',
                    ans: 'Не. Сви други претраживачи су онемогућени, да би се осигурала контрола приступа само дозвољеним интернет адресама.',
                },
                {
                    question: 'Како дозволити приступ одређеној интернет адреси?',
                    altQuestion: 'Kako dozvoliti pristup odredjenoj internet adresi?',
                    ans: 'На страници "Интернет", кликните на дугме "Додај" које се налази у горњем десном углу екрана. Након тога ће вам се отворити дијалог путем којег можете унети адресу којој хоћете да омогућите приступ.',
                },
                {
                    question: 'Како забранити приступ одређеној интернет адреси?',
                    altQuestion: 'Kako zabraniti pristup odredjenoj internet adresi?',
                    ans: 'На страници "Интернет", у табели са дозвољеним интернет адресама пронађите адресу за коју желите да онемогућите приступ и затим кликните на дугме "Уклони". Уколико се адреса не налази у овој табели, приступ њој је већ забрањен, па додатне акције нису потребне.',
                },
                {
                    question: 'Како функционише филтрирање адреса?',
                    altQuestion: 'Kako funkcionise filtriranje adresa?',
                    ans: 'Филтрирање служи за једноставно проналажење адресе од интереса уколико постоји велик број адреса. Након сваког унесеног карактера, табела са адресама се филтрира и приказују се само адресе које садрже тај карактер.',
                },
            ],
          },
      },
    }
  },
  computed: {
    faqData() {
      let tempData = JSON.parse(JSON.stringify(this.allData));
      
      for(const key in tempData) {
        tempData[key]['qandA'] = tempData[key]['qandA'].filter(item => (item.question.includes(this.faqSearchQuery) || item.altQuestion.includes(this.faqSearchQuery)));
      }

      for (const key in tempData) {
        if (tempData[key]['qandA'].length == 0) {
          delete tempData[key];
        }
      }

      return tempData;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';

.avatar-responsive {
  cursor: pointer;
}

.avatar-responsive:hover {
  .icon-responsive {
    width: 34px !important;
    height: 34px !important;
  }
}

</style>