export default [
  {
    title: 'Школска одељења',
    route: 'device_groups',
    icon: 'UsersIcon',
  },
  {
    title: 'Одељења',
    route: 'teacher_classes',
    icon: 'UsersIcon',
  },
  {
    title: 'Повезивање',
    route: 'connection',
    icon: 'CpuIcon',
  },
  {
    title: 'Уређаји',
    route: 'devices',
    icon: 'TabletIcon'
  },
  {
    title: 'Апликације',
    route: 'applications',
    icon: 'AirplayIcon'
  },
  {
    title: 'Профил',
    route: 'profile',
    icon: 'UserIcon'
  },
  {
    title: 'Интернет',
    route: 'web',
    icon: 'GlobeIcon'
  },
  {
    title: 'Помоћ',
    route: 'help',
    icon: 'HelpCircleIcon'
  }
]
