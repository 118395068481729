<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
            fluid
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Small_Coat_of_Arms_Belgrade.svg/800px-Small_Coat_of_Arms_Belgrade.svg.png"
            alt="Login V2"
            style="width: 50px;"
          />
        <h1 class="brand-text text-primary ml-1" style="margin-top: 8px;">
          БеоТаблет
        </h1>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Добродошли на БеоТаблет портал!
          </b-card-title>
          <b-card-text class="mb-2">
            Молимо вас улогујте се на ваш налог
          </b-card-text>

          <!-- form -->
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Имејл"
                label-for="login-email"
              >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    name="login-email"
                    placeholder="aleksandra@gmail.com"
                  />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group v-if="!pre_login">
                <div class="d-flex justify-content-between">
                  <label for="login-password">Лозинка</label>
                  <b-link v-b-modal.modal-info>
                    <small>Заборавили сте лозинку?</small>
                  </b-link>
                </div>
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="login"
              >
                Улогуј се
              </b-button>
            </b-form>

          <b-card-text class="text-center mt-2">
            <span>Tреба вам помоћ? Контактирајте нас путем имејла:</span>
            <br>
            <a href="mailto:podrska@beotablet.rs">podrska@beotablet.rs</a>
          </b-card-text>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>

    <b-modal id="modal-info" cancel-variant="secondary" cancel-title="Одустани" ok-title="Потврди" centered size="s" title="Заборављена лозинка" @ok="forgotPassword()">
      <b-card-text>
        <b-card-text>
          Уколико сте заборавили лозинку, можете контактирати техничко лице задужено за БеоТаблет систем у оквиру ваше школе које може да вам ресетује лозинку.
        </b-card-text>
        <b-card-text>
          <b>Важно:</b> уколико кликнете на дугме <b>Потврди</b> које се налази на дну ове картице ваша лозинка ће бити ресетована и нова лозинка ће бити послата на вашу имејл адресу.
          <br>
          <br>
          Ваша имејл адреса: <b>{{email}}</b>
        </b-card-text>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable vue/no-unused-components */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BModal, VBModal
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BModal,
    VBModal
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      email: '',
      sideImg: require('@/assets/images/pages/beotableticon.svg'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/beotableticon.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    pre_login() {
      return this.$store.getters['auth/pre_login'];
    }
  },
  methods: {
    login() {
      if("vibrate" in navigator){
        navigator.vibrate(50);
      }
      let credentials = {
        email: this.email,
        password: this.password
      }

      if (this.pre_login) {
        this.$store.dispatch('auth/preLogin', {v: this.$vs, credentials: credentials, toast: this.$bvToast})
      } else {
        this.$store.dispatch('auth/login', {v: this.$vs, credentials: credentials, toast: this.$bvToast})
      }
    },
    forgotPassword() {
      let credentials = {
        email: this.email
      }
      this.$store.dispatch('auth/forgotPassword', {v: this.$vs, credentials: credentials, toast: this.$bvToast})
    }
  },
  destroyed() {
    this.$store.commit('auth/SET_PRE_LOGIN', true);
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
