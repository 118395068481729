<template>
  <div v-if="device_groups.length > 0">
    <b-card title="Листа свих одељења и наставника у вашој школи">
      <b-row class="mb-2">
        <b-col cols="12" md="5" lg="4" xl="4">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Филтрирај" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Обриши
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-table :items="device_groups" :fields="fields" :filter="filter" responsive outlined small>
        <template #cell(num)="data">
          <div>{{ data.index + 1 }}</div>
        </template>

        <template #cell(group_name)="data">
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(teacher_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(email)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(school_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(choose)="row">
          <div class="nowrap-table-cell">
            <b-button variant="outline-primary" size="sm" class="custom-small-padding" @click="choose(row.item.id)" v-b-modal.modal-confirm>
              Одабери
            </b-button>
          </div>
        </template>

        <template #cell(add)="row">
          <div class="nowrap-table-cell">
            <b-button variant="outline-primary" size="sm" class="custom-small-padding" :disabled="row.item.email != '-'" @click="choose(row.item.teacher_id)" v-b-modal.modal-create>
              Креирај
            </b-button>
          </div>
        </template>

        <template #cell(password)="row">
          <div class="nowrap-table-cell">
            <b-button variant="outline-primary" size="sm" class="custom-small-padding" @click="choose(row.item.teacher_id)" v-b-modal.modal-generate>
              Генериши
            </b-button>
          </div>
        </template>

      </b-table>
    </b-card>

    <b-modal id="modal-confirm" cancel-variant="secondary" cancel-title="Не" ok-title="Да" centered size="s" title="Молимо вас да потврдите" @ok="chooseGroup()">
      <b-card-text>
        Да ли желите да видите податке везане за ово одељење?
      </b-card-text>
    </b-modal>

    <b-modal id="modal-create" cancel-variant="secondary" cancel-title="Одустани" ok-title="Даље" centered size="s" title="Креирање корисничког имена" @ok="openConfirmModal()">
      <b-card-text>
        <label for="createEmailInput">Корисничко име (имејл)</label>
        <b-form-input id="createEmailInput" v-model="new_email" type="text" placeholder="Унесите корисничко име" />
        <small style="margin-top: 10px;">
          Уколико наставничка имејл адреса није достављена приликом првобитног прикупљања података, путем ове контроле можете да креирате корисничко име
          који ће се користити приликом логовања на систем.
        </small>
      </b-card-text>
    </b-modal>

    <b-modal ref="modal-confirm-email" id="modal-confirm-email" cancel-variant="secondary" cancel-title="Не" ok-title="Да" centered size="s" title="Молимо вас да потврдите" @ok="createEmail()">
      <b-card-text>
        Да ли сте сигурни да желите да креирате корисничко име?<br>
        <b>Корисник</b>: {{getUser()}} <br>
        <b>Корисничко име</b>: {{new_email}} <br>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-generate" cancel-variant="secondary" cancel-title="Не" ok-title="Да" centered size="s" title="Молимо вас да потврдите" @ok="generatePassword()">
      <b-card-text>
        Да ли сте сигурни да желите да изгенеришете нову лозинку за овог корисника? 
        Након ове акције одабрани корисник више неће моћи да се улогује са старом лозинком. <br>
        Изгенерисана лозинка ће стићи на <b>вашу</b> имејл адресу.
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BCard, BTable, BButton, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BModal, VBModal, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    VBModal,
    BCardText
  },
  data() {
    return {
      fields: [
        { key: 'num', label: '#', sortable: false},
        { key: 'group_name', label: 'Одељење', sortable: true},
        { key: 'teacher_name', label: 'Наставник', sortable: false},
        { key: 'email', label: 'Корисничко име (Имејл)', sortable: false },
        { key: 'school_name', label: 'Школа', sortable: false },
        { key: 'choose', label: 'Одабери', sortable: false },
        { key: 'add', label: 'Корисничко име', sortable: false },
        { key: 'password', label: 'Генериши лозинку', sortable: false }
      ],
      filter: null,
      selected_id: 0,
      new_email: ''
    }
  },
  computed: {
    device_groups() {
      return this.$store.getters['school/device_groups'];
    }
  },
  methods: {
    choose(id) {
      this.selected_id = id;
    },
    chooseGroup() {
      this.$store.commit('school/CHOOSE_GROUP', this.selected_id);
      this.$store.commit('auth/SET_DEVICE_GROUP', this.selected_id);
      this.selected_id = 0;
      this.$bvToast.toast('Одељење успешно промењено.', { title: `Обавештење`, toaster: 'b-toaster-bottom-center', variant: 'info', autoHideDelay: 5000, solid: true});
    },
    openConfirmModal() {
      this.$refs['modal-confirm-email'].show();
    },
    getUser() {
      if (this.device_groups) {
        let item =  this.device_groups.find(item => item.id == this.selected_id);
        if (item) {
          return item.teacher_name;
        } else {
          return null;
        }
      }
      return null;
    },
    createEmail() {
      this.$store.dispatch('school/createEmail', {toast: this.$bvToast, data: {id: this.selected_id, email: this.new_email}});
      this.selected_id = 0;
      this.new_email = '';
    },
    generatePassword() {
      this.$store.dispatch('school/generatePassword', {toast: this.$bvToast, data: {id: this.selected_id}});
      this.selected_id = 0;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
