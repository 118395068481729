<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive" >
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <div class="smaller-on-small" v-if="account != 'teacher' || temp_groups.length > 1">
        <span class="font-medium">Одељење</span>: {{group_name != '' ? group_name : 'Није одабрано'}} <br>
        <span v-if="account != 'teacher'"><span class="font-medium">Наставник</span>: {{teacher_name != '' ? teacher_name : 'Није одабран'}}</span>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user" >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{email}}
            </p>
            <span class="user-status">{{account_type}}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/user.svg')" class="badge-minimal" badge-variant="success" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" v-b-modal.modal-passchange>
          <feather-icon size="16" icon="LockIcon" class="mr-50" />
          <span>Промена лозинке</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Излогуј се</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal id="modal-passchange" cancel-variant="secondary" cancel-title="Одустани" ok-title="Сачувај" centered size="s" title="Промена лозинке" @ok="changePassword()">
      <div>
        <label for="password">Тренутна лозинка</label>
        <b-form-input id="password" v-model="change.password" type="password" placeholder="Тренутна лозинка" />
      </div>
      <div class="mt-1">
        <label for="new_password">Нова лозинка</label>
        <b-form-input id="new_password" v-model="change.new_password" type="password" placeholder="Нова лозинка" />
      </div>
      <div class="mt-1">
        <label for="confirm_new_password">Потврдите нову лозинку</label>
        <b-form-input id="confirm_new_password" v-model="change.confirm_new_password" type="password" placeholder="Потврдите нову лозинку" />
      </div>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BInputGroup, BFormInput, BModal, VBModal } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BInputGroup, 
    BFormInput,  
    BModal, 
    VBModal
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() { 
    return {
      change: {
        password: '',
        new_password: '',
        confirm_new_password: ''
      }
    }
  },
  computed: {
    account_type() {
      if (this.$store.getters['auth/account_type'] == 'teacher') {
        return 'Наставнички налог'
      } else if (this.$store.getters['auth/account_type'] == 'school') {
        return 'Школски налог'
      } else {
        return 'Администраторски налог'
      }
    },
    account() {
      return this.$store.getters['auth/account_type'];
    },
    email() {
      return this.$store.getters['auth/email'];
    },
    group_name() {
      return this.$store.getters['school/group_name'];
    },
    teacher_name() {
      return this.$store.getters['school/teacher_name'];
    },
    temp_groups() {
      return this.$store.getters['auth/temp_groups'];
    }
  },
  methods: {
    logout() {
      this.$store.commit('auth/RESET');
      this.$router.push('/login');
    },
    changePassword() {
      if (this.change.new_password != this.change.confirm_new_password) {
        this.$bvToast.toast('Не подударају се нове лозинке.', {
          title: `Грешка`,
          toaster: 'b-toaster-bottom-center',
          variant: 'danger',
          solid: true,
        })
      } else if (this.change.new_password.length < 8) {
        this.$bvToast.toast('Лозинка мора да има макар 8 карактера.', {
          title: `Грешка`,
          toaster: 'b-toaster-bottom-center',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.$store.dispatch('auth/changePassword', {change: this.change, toast: this.$bvToast});
      }
      this.change = {
        password: '',
        new_password: '',
        confirm_new_password: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 620px) {
  .smaller-on-small {
    font-size: 0.85rem !important;
  }
}
  
</style>