import baseUrl from '../baseUrl.js'
import router from '../../router/index'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  pre_login: true,
  token: null,
  account_type: 'teacher',
  email: '',
  account_id: 0,
  device_group: 0,
  temp_groups: []
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    account_type: state => {
      return state.account_type;
    },
    email: state => {
      return state.email;
    },
    token: state => {
      return state.token;
    },
    pre_login: state => {
      return state.pre_login;
    },
    account_id: state => {
      return state.account_id;
    },
    device_group: state => {
      return state.device_group;
    },
    temp_groups: state => {
      return state.temp_groups;
    }
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_PRE_LOGIN(state, value) {
      state.pre_login = value;
    },
    UPDATE_TOKEN(state, value) {
      state.token = {
        headers: {
          Authorization: 'JWT ' + value.token
        }
      }
      state.account_type = value.account_type;
      state.email = value.email;
      state.account_id = value.account_id;
      if (value.device_group.length == 1) {
        state.device_group = value.device_group[0];
      } else {
        state.device_group = 0;
        state.temp_groups = value.device_group;
      }
    },
    LOGOUT_USER(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
      router.push('/login');
    },
    SET_DEVICE_GROUP(state, value) {
      state.device_group = value;
    },
    UPDATE_PASSWORD(state, value) {
      state.token = {
        headers: {
          Authorization: 'JWT ' + value.token
        }
      }
    }
  },
  actions: {
    preLogin({commit}, payload) {
      axios.post(baseUrl + 'auth/preLogin/', payload.credentials).then(
        res => {
          if (res.data.first_login) {
            payload.toast.toast('Лозинка је послата на вашу имејл адресу.', { title: `Обавештење`, toaster: 'b-toaster-bottom-center', variant: 'info', autoHideDelay: 10000, solid: true});
          }
          commit('SET_PRE_LOGIN', false);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    login({commit, dispatch}, payload) {
      axios.post(baseUrl + 'auth/login/', payload.credentials).then(
        res => {
          commit('UPDATE_TOKEN', res.data);
          if (res.data.account_type == 'teacher') {
            if (res.data.device_group.length == 1) {
              router.push('/');
            } else {
              router.push('/teacher_classes');
            }
          } else if (res.data.account_type == 'school') {
            dispatch('school/getAllDeviceGroups', null, { root: true });
            router.push('/device_groups');
          }
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    changePassword({rootState, commit}, payload) {
      axios.post(baseUrl + 'auth/changePassword/', payload.change, rootState.auth.token ).then(
        res => {
          commit('UPDATE_PASSWORD', res.data);
          payload.toast.toast('Лозинка је успешно промењена.', { title: `Обавештење`, toaster: 'b-toaster-bottom-center', variant: 'info', autoHideDelay: 10000, solid: true});
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    forgotPassword({rootState}, payload) {
      axios.post(baseUrl + 'auth/forgotPassword/', payload.credentials, rootState.auth.token ).then(
        () => {
          payload.toast.toast('Лозинка је успешно промењена.', { title: `Обавештење`, toaster: 'b-toaster-bottom-center', variant: 'info', autoHideDelay: 10000, solid: true});
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
  },
}
