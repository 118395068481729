<template>
  <div v-if="applications">
    <b-card title="Листа свих контролисаних апликација на таблетима у вашем одељењу">

      <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div v-for="(item, index) in applications" :key="index" class="application-holder" :id=" index == 0 ? 'app-help' : ''">
          <div class="flip-card button-emulator">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img v-if="item.application_image" :src="item.application_image" alt="logo" class="application-image">
                <img v-if="!item.application_image" src="../assets/images/favicon.png" alt="logo" class="application-image">
              </div>
              <div class="flip-card-back" style="padding-top: 10px;">
                <div @click="configureApp(index)" v-b-modal.modal-configure>
                  <feather-icon icon="SettingsIcon" size="80" />
                </div>
              </div>
            </div>
          </div> 
          <div class="application-name">{{item.name}}</div>
          <div>
            <b-badge v-if="item.status == 'installed'" variant="success">Омогућена</b-badge>
            <b-badge v-if="item.status !== 'installed'" variant="danger">Онемогућена</b-badge>
          </div>
          <div class="custom-overlay" v-if="item.can_change == 'no'" v-b-tooltip.hover.v-primary title="Забрањена измена. Мора да протекне више од 15 минута од претходне измене.">
            <img src="../assets/images/lock.png" alt="" class="locked-icon" >
          </div>
        </div>
      </div>
      
    </b-card>

    <b-modal id="modal-configure" cancel-variant="secondary" cancel-title="Одустани" ok-title="Даље" centered size="s" title="Контрола апликације" @ok="openConfirmModal()">
      <b-card-text v-if="app">
        <div style="width: 100%; text-align: center;" v-if="app.application_image">
          <img :src="app.application_image" alt="logo" class="application-image">
          <div class="application-name">{{app.name}}</div>
        </div>
        <b-card-text>
          Овим путем можете да инсталирате или уклоните апликацију са свих уређаја у вашем одељењу. Да ли желите да наставите?
        </b-card-text>
      </b-card-text>
    </b-modal>

    <b-modal ref="modal-confirm" id="modal-confirm" cancel-variant="secondary" cancel-title="Одустани" ok-title="Да" centered size="s" title="Молимо вас потврдите" @ok="saveAppConfiguration()">
      <div v-if="app">
        <div style="width: 100%; text-align: center;" v-if="app.application_image">
          <img :src="app.application_image" alt="logo" class="application-image">
          <div class="application-name">{{app.name}}</div>
        </div>
        <b-card-text v-if="app.status == 'installed'" class="mt-1">
          Да ли сте сигурни да желите да уклоните ову апликацију са свих уређаја у вашем одељењу?
        </b-card-text>
        <b-card-text v-if="app.status != 'installed'" class="mt-1">
          Да ли сте сигурни да желите да инсталирате ову апликацију на све уређаје у вашем одељењу?
        </b-card-text>
      </div>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BCard, BBadge, BButton, BModal, VBModal, BCardText, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BCardText
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  mounted() {
    this.$store.dispatch('applications/getAllApplications', {toast: this.$bvToast});
  },
  data() {
    return {
      app: null
    }
  },
  computed: {
    applications() {
      return this.$store.getters['applications/applications'];
    }
  },
  methods: {
    configureApp(index) {
      this.app = this.applications[index];
    },
    openConfirmModal() {
      this.$refs['modal-confirm'].show();
    },
    saveAppConfiguration() {
      this.$store.dispatch('applications/saveAppConfiguration', {app: {id: this.app.id, action: this.app.status == 'installed' ? 'removal' : 'installation'}, toast: this.$bvToast});
      this.app = null;
    }
  },
  destroyed() {
    this.$store.commit('applications/SET_APPLICATIONS', {applications: []});
  }
}
</script>

<style lang="scss" scoped>
  
</style>
