<template>
  <div v-if="qr_code">
    <b-card :title="'Повезивање таблета ученика са БеоТаблет системом за одељење: ' + class_name">
      <div class="mt-2 mb-2 text-center">
        <vue-qrcode :value="qr_code" errorCorrectionLevel="H" :width="currentBreakPoint != 'xs' ? 360 : 260"/>
        <p>
          QR код за повезивање.
        </p>
        <b-button id="qr-print-icon" @click="openPrint()" variant="primary" size="sm">
          <feather-icon
            icon="PrinterIcon"
            class="mr-50"
          />
          <span class="align-middle">Штампај</span>
        </b-button>
      </div>
      <p>
        <ul>
          <li><strong>Корак 1</strong>: Преузимање основног програма таблета</li>
          <li><strong>Корак 2</strong>: Стартовање таблета, након чега ће се појавити БеоТаблет апликација и затражити повезивање уређаја са одељењем</li>
          <li><strong>Корак 3</strong>: Активираће се камера и након тога је потребно скенирати QR код који се налази изнад</li>
          <li><strong>Корак 4</strong>: Након скенирања, таблет ће се повезати са вашим одељењем и систем ће применити заштитне полисе и извршити инсталацију потребних апликација</li>
        </ul>
      </p>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    BCard,
    VueQrcode,
    BButton
  },
  mounted() {
    this.$store.dispatch('connection/getQRcode', {toast: this.$bvToast});
  },
  computed: {
    qr_code() {
      return JSON.stringify(this.$store.getters['connection/qr_code']);
    },
    class_name() {
      return JSON.stringify(this.$store.getters['connection/class_name']);
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint'];
    }
  },
  methods: {
    openPrint() {
      window.print()
    }
  },
  destroyed() {
    this.$store.commit('connection/RESET');
  }
}
</script>

<style>
  @media print {
    .main-menu {
      display:none;
    }
    .breadcrumbs-top {
      display:none;
    }
    #qr-print-icon {
      display:none;
    }
    footer {
      display:none;
    }
  }
</style>
