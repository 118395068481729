<template>
  <div v-if="device_groups.length > 0">
    <b-card title="Листа свих одељења којима ви предајете">
      <b-row class="mb-2">
        <b-col cols="12" md="5" lg="4" xl="4">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Филтрирај" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Обриши
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <div id="table-class-choose">
        <b-table :items="device_groups" :fields="fields" :filter="filter" responsive outlined small>
        <template #cell(num)="data">
          <div>{{ data.index + 1 }}</div>
        </template>

        <template #cell(group_name)="data">
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(teacher_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(email)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(school_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(choose)="row">
          <div class="nowrap-table-cell">
            <b-button variant="outline-primary" size="sm" class="custom-small-padding" @click="choose(row.item.id)" v-b-modal.modal-confirm>
              Одабери
            </b-button>
          </div>
        </template>

        </b-table>
      </div>
    </b-card>

    <b-modal id="modal-confirm" cancel-variant="secondary" cancel-title="Не" ok-title="Да" centered size="s" title="Молимо вас да потврдите" @ok="chooseGroup()">
      <b-card-text>
        Да ли желите да видите податке везане за ово одељење?
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BCard, BTable, BButton, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BModal, VBModal, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    VBModal,
    BCardText
  },
  mounted() {
    this.$store.dispatch('school/getAllDeviceGroups', {toast: this.$bvToast});
  },
  data() {
    return {
      fields: [
        { key: 'num', label: '#', sortable: false},
        { key: 'group_name', label: 'Одељење', sortable: true},
        { key: 'teacher_name', label: 'Наставник', sortable: false},
        { key: 'email', label: 'Корисничко име (Имејл)', sortable: false },
        { key: 'school_name', label: 'Школа', sortable: false },
        { key: 'choose', label: 'Одабери', sortable: false }
      ],
      filter: null,
      selected_id: 0,
      new_email: ''
    }
  },
  computed: {
    device_groups() {
      return this.$store.getters['school/device_groups'];
    }
  },
  methods: {
    choose(id) {
      this.selected_id = id;
    },
    chooseGroup() {
      this.$store.commit('school/CHOOSE_GROUP', this.selected_id);
      this.$store.commit('auth/SET_DEVICE_GROUP', this.selected_id);
      this.selected_id = 0;
      this.$bvToast.toast('Одељење успешно промењено.', { title: `Обавештење`, toaster: 'b-toaster-bottom-center', variant: 'info', autoHideDelay: 5000, solid: true});
    },
    openConfirmModal() {
      this.$refs['modal-confirm-email'].show();
    },
    getUser() {
      if (this.device_groups) {
        let item =  this.device_groups.find(item => item.id == this.selected_id);
        if (item) {
          return item.teacher_name;
        } else {
          return null;
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
