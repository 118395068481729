import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  profile: null
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    profile: state => {
      return state.profile;
    },
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_PROFILE(state, value) {
      state.profile = value;
    },
  },
  actions: {
    getProfile({rootState, commit}, payload) {
      axios.get(baseUrl + 'profile/getProfile/' + rootState.auth.device_group, rootState.auth.token).then(
        res => {
          commit('SET_PROFILE', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    setProfileBlock({rootState, commit}, payload) {
      axios.post(baseUrl + 'profile/setProfileBlock/' + rootState.auth.device_group + '/', payload.blocks, rootState.auth.token).then(
        () => {
          axios.get(baseUrl + 'profile/getProfile/' + rootState.auth.device_group, rootState.auth.token).then(
            res => {
              commit('SET_PROFILE', res.data);
              payload.toast.toast('Време забране коришћења успешно промењено.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
            }
          ).catch(
            err => {
              errorHandler(payload.toast, err);
            }
          );
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    }
  },
}
