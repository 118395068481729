import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'connection',
      component: () => import('@/views/Connection.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Повезивање',
        breadcrumb: [
          {
            text: 'Повезивање',
            active: true,
          },
        ],
      },
    },
    {
      path: '/device_groups',
      name: 'device_groups',
      component: () => import('@/views/DeviceGroups.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Школска одељења',
        breadcrumb: [
          {
            text: 'Школска одељења',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teacher_classes',
      name: 'teacher_classes',
      component: () => import('@/views/TeacherClasses.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Одељења',
        breadcrumb: [
          {
            text: 'Одељења',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/Devices.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Уређаји',
        breadcrumb: [
          {
            text: 'Уређаји',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applications',
      name: 'applications',
      component: () => import('@/views/Applications.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Апликације',
        breadcrumb: [
          {
            text: 'Апликације',
            active: true,
          },
        ],
      },
    },
    {
      path: '/device_applications',
      name: 'device_applications',
      component: () => import('@/views/DeviceApplications.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Апликације уређаја',
        breadcrumb: [
          {
            text: 'Апликације уређаја',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Профил',
        breadcrumb: [
          {
            text: 'Профил',
            active: true,
          },
        ],
      },
    },
    {
      path: '/web',
      name: 'web',
      component: () => import('@/views/Web.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Интернет',
        breadcrumb: [
          {
            text: 'Интернет',
            active: true,
          },
        ],
      },
    },
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/Help.vue'),
      beforeEnter(to, from, next) {
        if (store.state.auth.token) {
          next();
        } else {
          next('/login');
        }
      },
      meta: {
        pageTitle: 'Помоћ',
        breadcrumb: [
          {
            text: 'Помоћ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
