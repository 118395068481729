import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  device_groups: [],
  teacher_name: '',
  group_name: ''
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    device_groups: state => {
      return state.device_groups;
    },
    teacher_name: state => {
      return state.teacher_name;
    },
    group_name: state => {
      return state.group_name;
    },
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_DEVICE_GROUPS(state, value) {
      state.device_groups = value;
    },
    SET_TEACHER_NAME(state, value) {
      state.teacher_name = value;
    },
    SET_GROUP_NAME(state, value) {
      state.group_name = value;
    },
    CHOOSE_GROUP(state, value) {
      let g = state.device_groups.find(element => element.id == value);
      state.group_name = g.group_name;
      state.teacher_name = g.teacher_name;
    }
  },
  actions: {
    getAllDeviceGroups({rootState, commit}, payload) {
      axios.get(baseUrl + 'school/getDeviceGroups', rootState.auth.token).then(
        res => {
          commit('SET_DEVICE_GROUPS', res.data.device_groups);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    createEmail({rootState, commit}, payload) {
      axios.post(baseUrl + 'school/createEmail/', payload.data ,rootState.auth.token).then(
        () => {
          axios.get(baseUrl + 'school/getDeviceGroups', rootState.auth.token).then(
            res => {
              commit('SET_DEVICE_GROUPS', res.data.device_groups);
              payload.toast.toast('Корисничко име успешно постављено.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
            }
          ).catch(
            err => {
              errorHandler(payload.toast, err);
            }
          );
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    generatePassword({rootState}, payload) {
      axios.put(baseUrl + 'school/generatePassword/', payload.data, rootState.auth.token).then(
        () => {
          payload.toast.toast('Лозинка успешно изгенерисана и послата на вашу имејл адресу.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
  },
}
