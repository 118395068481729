<template>
  <div v-if="applications">
    <b-card :title="'Листа свих контролисаних апликација на уређају: ' + device_name">

      <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div v-for="(item, index) in applications" :key="index" class="application-holder" style="height: 195px" :id=" index == 0 ? 'device-app-help' : ''">
          <div>
            <img v-if="item.application_image" :src="item.application_image" alt="logo" class="application-image">
            <img v-if="!item.application_image" src="../assets/images/favicon.png" alt="logo" class="application-image">
          </div>
          <div class="application-name">{{item.name}}</div>
          <div>
            <b-badge :variant="item.status == 'success' ? 'success' : (item.status == 'failed' ? 'danger' : 'warning')" style="width: 100%; white-space: pre-line; padding: .3rem .35rem">
              {{ getLabel(item.status, item.action) }}
            </b-badge>
          </div>
        </div>
      </div>
      
    </b-card>

    <b-card :title="'Обавештења за уређај: ' + device_name">
      <b-row>
        <b-col cols="12" lg="7" xl="7">
          <app-timeline>
            <app-timeline-item v-for="(item, index) in this.events" :key="index"
              :title="getTitle(item.event_type)"
              :subtitle="getSubtitle(item.event_type)"
              :icon="getIcon(item.event_type)"
              :time="item.time"
              :variant="getVariant(item.event_type)"
            />
          </app-timeline>
        </b-col>
      </b-row>
      <b-button v-if="events.length % 5 == 0" class='mt-3' variant="outline-primary" @click="getMore()">Прикажи још</b-button>
    </b-card>

  </div>
</template>

<script>
import { BCard, BBadge, BRow, BCol, BButton } from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BButton,
    AppTimeline,
    AppTimelineItem
  },
  mounted() {
    this.$store.dispatch('applications/getAppsForDevice', {toast: this.$bvToast});
    this.$store.dispatch('applications/getEventsForDevice', {toast: this.$bvToast, page: this.page});
  },
  data() {
    return {
      page: 1
    }
  },
  computed: {
    applications() {
      return this.$store.getters['applications/applications_for_device'];
    },
    events() {
      return this.$store.getters['applications/events_for_device'];
    },
    device_name() {
      return this.$store.getters['applications/device_name'];
    },
    account() {
      return this.$store.getters['auth/account_id'];
    }
  },
  methods: {
    getLabel(status, action) {
      if (status == 'success') {
        if (action == 'installation') {
          return 'Успешно инсталирана';
        } else if (action == 'removal') {
          return 'Успешно уклоњена';
        } else if (action == 'update') {
          return 'Успешно ажурирана';
        }
      } else if (status == 'failed') {
        if (action == 'installation') {
          return 'Неуспешна инсталација';
        } else if (action == 'removal') {
          return 'Неуспешно уклањање';
        } else if (action == 'update') {
          return 'Неуспешно ажурирање';
        }
      } else if (status == 'pending') {
        if (action == 'installation') {
          return 'Чека се инсталација';
        } else if (action == 'removal') {
          return 'Чека се уклањање';
        } else if (action == 'update') {
          return 'Чека се ажурирање';
        }
      }
    },
    getTitle(event_type) {
      if (event_type == 'DEVICE_SHUTDOWN' || event_type == 'DEVICE_START' || event_type == 'DEVICE_REBOOT') {
        return 'Уређај';
      } else if (event_type == 'SIM_CHANGED' || event_type == 'SIM_REMOVED') {
        return 'Сим картица';
      } else if (event_type == 'STORAGE_LOW') {
        return 'Меморија';
      } else if (event_type == 'BATTERY_LOW') {
        return 'Батерија';
      }
      return '';
    },
    getSubtitle(event_type) {
      if (event_type == 'DEVICE_SHUTDOWN') {
        return 'Уређај искључен';
      } else if (event_type == 'DEVICE_START') {
        return 'Уређај укључен';
      } else if (event_type == 'DEVICE_REBOOT') {
        return 'Уређај рестартован';
      } else if (event_type == 'SIM_CHANGED') {
        return 'Сим картица промењена';
      } else if (event_type == 'SIM_REMOVED') {
        return 'Сим картица уклоњена';
      } else if (event_type == 'STORAGE_LOW') {
        return 'Меморија близу прага пражњења';
      } else if (event_type == 'BATTERY_LOW') {
        return 'Батерија близу прага пражњења';
      }
      return '';
    },
    getIcon(event_type) {
      if (event_type == 'DEVICE_SHUTDOWN' || event_type == 'DEVICE_START' || event_type == 'DEVICE_REBOOT') {
        return 'PowerIcon';
      } else if (event_type == 'SIM_CHANGED') {
        return 'LogInIcon';
      } else if (event_type == 'SIM_REMOVED') {
        return 'LogOutIcon';
      } else if (event_type == 'STORAGE_LOW') {
        return 'DatabaseIcon';
      } else if (event_type == 'BATTERY_LOW') {
        return 'BatteryChargingIcon';
      }
      return '';
    },
    getVariant(event_type) {
      if (event_type == 'DEVICE_START') {
        return 'primary';
      } else if (event_type == 'STORAGE_LOW' || event_type == 'BATTERY_LOW' || event_type == 'DEVICE_REBOOT') {
        return 'warning';
      }
      return 'danger';
    },
    getMore() {
      this.page += 1;
      this.$store.dispatch('applications/getEventsForDevice', {toast: this.$bvToast, page: this.page});
    }
  },
  destroyed() {
    this.$store.commit('applications/RESET');
  }
}
</script>

<style lang="scss" scoped>

</style>