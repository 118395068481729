<template>
  <div v-if="web">
    <b-card id="page-description" title="Листа свих дозвољених интернет адреса у вашем одељењу">

      <b-row class="mb-2">
        <b-col cols="9" md="5" lg="4" xl="4" style="padding-right: 0.5rem!important;">
          <b-input-group id="filter-address" size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Филтрирај" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Обриши
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="3" md="7" lg="8" xl="8">
          <b-button id="add-new" style="float: right; padding: 0.575rem 1rem !important" variant="outline-primary" size="sm" v-b-modal.modal-add>
            Додај
          </b-button>
        </b-col>
      </b-row>
      
      <div id="address-table">
        <b-table :items="web" :fields="fields" :filter="filter" responsive outlined small>
          <template #cell(num)="data">
            <div>{{ data.index + 1 }}</div>
          </template>

          <template #cell(name)="data">
            <div class="nowrap-table-cell">{{ data.value }}</div>
          </template>

          <template #cell(group_name)="data" >
            <div class="nowrap-table-cell">{{ data.value }}</div>
          </template>

          <template #cell(status)>
            <div class="nowrap-table-cell">
              <b-badge variant="success">
                Дозвољено
              </b-badge>
            </div>
          </template>

          <template #cell(remove)="row">
            <div class="nowrap-table-cell">
              <b-button variant="outline-danger" size="sm" class="custom-small-padding" @click="setRemove(row.item.id)" v-b-modal.modal-remove>
                Уклони
              </b-button>
            </div>
          </template>

        </b-table>
      </div>
      
    </b-card>

    <b-modal id="modal-remove" cancel-variant="secondary" cancel-title="Не" ok-title="Да" centered size="s" title="Молимо вас да потврдите" @ok="removeUrl()">
      <b-card-text>
        Да ли сте сигурни да желите да уклоните ову интернет страницу из листе дозвољених страница?
      </b-card-text>
    </b-modal>

    <b-modal id="modal-add" cancel-variant="secondary" cancel-title="Одустани" ok-title="Сачувај" centered size="s" title="Додавање нове интернет странице" @ok="addUrl()">
      <b-card-text>
        <b-input-group size="sm">
          <b-card-text>
            Молимо вас унесите URL интернет странице коју желите да додате.
            Након што ово урадите, сваки уређај у вашем одељењу ће моћи да приступи овој интернет страници.
          </b-card-text>
          <b-form-input id="urlInput" v-model="add_url" placeholder="www.rts.rs" />
        </b-input-group>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BCard, BTable, BInputGroup, BFormInput, BInputGroupAppend, BButton, BRow, BCol, BBadge, BModal, VBModal, BCardText } from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BBadge,
    BModal,
    VBModal,
    BCardText
  },
  mounted() {
    this.$store.dispatch('web/getAllWeb', {toast: this.$bvToast});
  },
  data() {
    return {
      fields: [
        { key: 'num', label: '#', sortable: false},
        { key: 'name', label: 'Интернет адреса', sortable: false},
        { key: 'group_name', label: 'Одељење', sortable: false},
        { key: 'status', label: 'Статус', sortable: false },
        { key: 'remove', label: 'Уклони', sortable: false },
      ],
      filter: null,
      remove_id: 0,
      add_url: '',
    }
  },
  computed: {
    web() {
      return this.$store.getters['web/web'];
    }
  },
  methods: {
    setRemove(id) {
      this.remove_id = id;
    },
    removeUrl() {
      this.$store.dispatch('web/removeUrl', {toast: this.$bvToast, url_id: this.remove_id});
      this.remove_id = 0;
    },
    addUrl() {
      if (this.add_url == '') {
        this.$bvToast.toast('Интернет адреса не може бити празна.', { title: `Упозорење`, toaster: 'b-toaster-bottom-center', variant: 'warning', solid: true});
      } else {
        this.$store.dispatch('web/addUrl', {toast: this.$bvToast, url: {name: this.add_url}});
      }
      this.add_url = '';
    }
  },
  destroyed() {
    this.$store.commit('web/SET_WEB', {web: []});
  }
}
</script>

<style lang="scss">

</style>
