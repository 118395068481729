import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  web: []
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    web: state => {
      return state.web;
    },
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_WEB(state, value) {
      state.web = value.web;
    },
  },
  actions: {
    getAllWeb({rootState, commit}, payload) {
      axios.get(baseUrl + 'web/getAllWeb/' + rootState.auth.device_group, rootState.auth.token).then(
        res => {
          commit('SET_WEB', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    removeUrl({rootState, commit}, payload) {
      axios.delete(baseUrl + 'web/removeUrl/' + rootState.auth.device_group + '/' + payload.url_id + '/', rootState.auth.token).then(
        () => {
          axios.get(baseUrl + 'web/getAllWeb/' + rootState.auth.device_group, rootState.auth.token).then(
            res => {
              commit('SET_WEB', res.data);
              payload.toast.toast('Интернет адреса успешно уклоњена.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
            }
          ).catch(
            err => {
              errorHandler(payload.toast, err);
            }
          );
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    addUrl({rootState, commit}, payload) {
      axios.post(baseUrl + 'web/addUrl/' + rootState.auth.device_group + '/', payload.url, rootState.auth.token).then(
        () => {
          axios.get(baseUrl + 'web/getAllWeb/' + rootState.auth.device_group, rootState.auth.token).then(
            res => {
              commit('SET_WEB', res.data);
              payload.toast.toast('Интернет адреса успешно додата.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
            }
          ).catch(
            err => {
              errorHandler(payload.toast, err);
            }
          );
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    }
  },
}
