import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  applications: [],
  device_for_apps: 0,
  device_name: '',
  applications_for_device: [],
  events_for_device: [],
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    applications: state => {
      return state.applications;
    },
    applications_for_device: state => {
      return state.applications_for_device;
    },
    device_name: state => {
      return state.device_name;
    },
    events_for_device: state => {
      return state.events_for_device;
    },
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_APPLICATIONS(state, value) {
      state.applications = value.applications;
    },
    SET_DEVICE_FOR_APPS(state, value) {
      state.device_for_apps = value.id;
      state.device_name = value.name;
    },
    SET_APPLICATIONS_FOR_DEVICE(state, value) {
      state.applications_for_device = value.applications;
    },
    SET_EVENTS_FOR_DEVICE(state, value) {
      state.events_for_device.push(...value.events);
    },
  },
  actions: {
    getAllApplications({rootState, commit}, payload) {
      axios.get(baseUrl + 'application/getAllApplications/' + rootState.auth.device_group, rootState.auth.token).then(
        res => {
          commit('SET_APPLICATIONS', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    saveAppConfiguration({rootState, commit}, payload) {
      axios.post(baseUrl + 'application/saveAppConfiguration/' + rootState.auth.device_group + '/', payload.app, rootState.auth.token).then(
        () => {
          axios.get(baseUrl + 'application/getAllApplications/' + rootState.auth.device_group, rootState.auth.token).then(
            res => {
              commit('SET_APPLICATIONS', res.data);
              payload.toast.toast('Конфигурација апликације успешно измењена.', { title: `Успех`, toaster: 'b-toaster-bottom-center', variant: 'success', solid: true});
            }
          ).catch(
            err => {
              errorHandler(payload.toast, err);
            }
          );
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    getAppsForDevice({rootState, state, commit}, payload) {
      axios.get(baseUrl + 'application/getAppsForDevice/' + state.device_for_apps, rootState.auth.token).then(
        res => {
          commit('SET_APPLICATIONS_FOR_DEVICE', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
    getEventsForDevice({rootState, state, commit}, payload) {
      axios.get(baseUrl + 'event/getEvents/' + state.device_for_apps + '/' + payload.page, rootState.auth.token).then(
        res => {
          commit('SET_EVENTS_FOR_DEVICE', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
  },
}
