import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import connection from './connection'
import devices from './devices'
import web from './web'
import profile from './profile'
import applications from './applications'
import school from './school'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    connection,
    devices,
    web,
    profile,
    applications,
    school
  },
  strict: process.env.DEV,
})
