import router from '../router/index';

function errorHandler(toast, err) {
    if(err.response.status == 404 && err.response.data.message == 'User with this email doesn\'t exist') {
        toast.toast('Не постоји корисник са овим имејлом.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 400 && err.response.data.message == 'Incorrect password') {
        toast.toast('Неисправна лозинка.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 400 && err.response.data.message == 'Email exists') {
        toast.toast('Корисничко име већ постоји. Молимо вас одаберите друго корисничко име.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 400 && err.response.data.message == 'Email too short') {
        toast.toast('Пректратко корисничко име. Корисничко име мора имати макар 8 карактера.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 400 && err.response.data.message == 'Profile already has this url whitelisted') {
        toast.toast('Адреса се већ налази у листи дозвољених адреса.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 400 && err.response.data.message == 'Passwords mismatch') {
        toast.toast('Лозинке се не подударају.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 404 && err.response.data.message == 'No device group with this id') {
        toast.toast('Одабрано одељење не постоји.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    } else if (err.response.status == 403 && err.response.data.detail == 'Token expired') {
        toast.toast('Ваша сесија је истекла. Молимо улогујте се поново.', { title: `Упозорење`, toaster: 'b-toaster-bottom-center', variant: 'warning', solid: true})
        router.push('/login');
    } else if (err.response.status == 403) {
        toast.toast('Акција није дозвољена.', { title: `Упозорење`, toaster: 'b-toaster-bottom-center', variant: 'warning', solid: true});
    } else {
        toast.toast('Догодила се неочекивана грешка. Уколико ово настави да вам се дешава, молимо контактирајте нашу подршку.', { title: `Грешка`, toaster: 'b-toaster-bottom-center', variant: 'danger', solid: true});
    }
    
}

export default errorHandler
