<template>
<div>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div v-if="$route.name != 'help' && $route.name != 'device_groups'" id="tour-page" style="display: flex; float:right; margin-top: -26px; margin-right: 3px;">
            <span class="app-help" @click="getHelp">
              <feather-icon  icon="HelpCircleIcon" size="28"/>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-col>
    
  </b-row>
  <app-tour :steps="tourSteps" />
</div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTour from '@core/components/app-tour/AppTour.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    AppTour
  },
  computed: {
    tourSteps() {
      if (this.$route.name == 'web') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите и управљате свим интернет адресама којима могу да приступе ваши ученици путем њихових таблета.',
          },
          {
            target: '#address-table',
            header: {
              title: 'Табела са адресама',
            },
            content: 'У овој табели можете да видите све тренутно дозвољене интернет адресе. Кликом на дугме "Уклони" одговарајућe адресе, можете да је уклоните из ове листе након чега ваши ученици неће више моћи да приступе тој адреси.',
          },
          {
            target: '#add-new',
            header: {
              title: 'Додавање адресе',
            },
            content: 'Након клика на ово дугме ће вам се отворити дијалог за додавање нове интернет адресе. Када сачувате нову адресу, ваши ученици ће моћи да приступе тој адреси путем њихових таблета.',
          },
          {
            target: '#filter-address',
            header: {
              title: 'Филтрирање',
            },
            content: 'Уколико у табели постоји велик број интернет адреса, можете да их филтрирате путем овог поља за унос. Кликом на дугме "Обриши", бришете филтер и у табелу се враћа приказ свих адреса.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни како треба да извршите одређену акцију, на страници "Помоћ" имате детаљно објашњену сваку могућност за управљање интернет адресама.',
          }
        ]
      } else if (this.$route.name == 'profile') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите тренутно подешавање таблета из вашег одељења. Такође можете да измените времена која означавају почетак и крај забране корићења таблета.',
          },
          {
            target: '#profile-notifications',
            header: {
              title: 'Обавештења',
            },
            content: 'У овој секцији су приказане акције таблет уређаја које се сакупљају у виду обавештења а могу бити релевантне у случају проблема. Само администратори могу да промене ово подешавање.',
          },
          {
            target: '#profile-block',
            header: {
              title: 'Забрана коришћења',
            },
            content: 'У овој секцији можете да видите време када су таблети ваших ученика блокирани за коришћење.',
          },
          {
            target: '#profile-web',
            header: {
              title: 'Интернет адресе',
            },
            content: 'У овој секцији можете да видите све интернет адресе којима могу да приступе ваши ученици путем њихових таблета.',
          },
          {
            target: '#profile-apps',
            header: {
              title: 'Контролисане апликације',
            },
            content: 'У овој секцији можете да видите све апликације контролисане путем БеоТаблет система. Са десне стране можете да видите статус апликције, да ли је појединачна апликација тренутно инсталирана или уклоњена са таблета.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни како треба да извршите одређену акцију, на страници "Помоћ" имате детаљно објашњену сваку могућност за измену подешавања таблета.',
          }
        ]
      } else if (this.$route.name == 'applications') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите листу свих конторлисаних апликација на таблетима у вашем одељењу. Само апликације из ове листе могу да се инсталирају на таблетима. Уколико је апликација већ инсталирана путем контрола на овој страници можете да је уклоните.',
          },
          {
            target: '#app-help',
            header: {
              title: 'Апликација',
            },
            content: 'Овде можете да видите једну апликацију која се контролише. На дну картице је приказан статус апликације. Кликом на лого апликације отвориће вам се контрола путем које можете да инсталирате или уклоните апликацију са уређаја у зависности од њеног тренутног статуса.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни како треба да извршите одређену акцију, на страници "Помоћ" имате детаљно објашњену сваку могућност за контролу апликација на таблетима.',
          }
        ]
      } else if (this.$route.name == 'devices') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите све таблет уређаје у вашем одељењу. Уређаји се распознају на основу њиховог кода.',
          },
          {
            target: '#device-table',
            header: {
              title: 'Табела са уређајима',
            },
            content: 'У овој табели можете да видите све таблет уређаје. Кликом на дугме "Преглед" одговарајућег уређаја, можете да видите детаље тог уређаја (статус контролисаних апликација и обавештења).',
          },
          {
            target: '#filter-devices',
            header: {
              title: 'Филтрирање',
            },
            content: 'Уколико у табели постоји велик број уређаја, можете да их филтрирате путем овог поља за унос. Кликом на дугме "Обриши", бришете филтер и у табелу се враћа приказ свих уређаја.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни како треба да извршите одређену акцију, на страници "Помоћ" имате детаљно објашњену сваку могућност за управљање уређајима.',
          }
        ]
      } else if (this.$route.name == 'connection') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите QR код за повезивање таблет уређаја са вашим одељењем.',
          },
          {
            target: '#qr-print-icon',
            header: {
              title: 'Опис странице',
            },
            content: 'Кликом на ово дугме, отвориће вам се дијалог за штампање QR кода са упутствима. (Изглед овог дијалога зависи од претраживача који користите).',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни како треба да извршите одређену акцију, на страници "Помоћ" имате детаљно објашњену сваки корак за повезивање уређаја.',
          }
        ]
      } else if (this.$route.name == 'device_applications') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите листу свих конторлисаних апликација за одабрани уређај.',
          },
          {
            target: '#device-app-help',
            header: {
              title: 'Апликација',
            },
            content: 'Овде можете да видите једну апликацију која се контролише. На дну картице је приказан статус апликације.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни шта представњају информације на овој страници, на страници "Помоћ" имате детаљно објашњену сваку могућност која се тиче контролисаних апликација на уређају.',
          }
        ]
      } else if (this.$route.name == 'teacher_classes') {
        return [
          {
            target: '#tour-page',
            header: {
              title: 'Опис странице',
            },
            content: 'На овој страници можете да видите листу свих одељења којима ви предајете.',
          },
          {
            target: '#table-class-choose',
            header: {
              title: 'Табела са одељењима',
            },
            content: 'У овој табели можете да видите сводељења којима ви предајете. Кликом на дугме "Oдабери" одговарајућег одељења одлучујете за које одељење желите да видите информације у оквиру портала.',
          },
          {
            target: '#tour-page',
            header: {
              title: 'Помоћ',
            },
            content: 'Уколико нисте сигурни шта представњају информације на овој страници, на страници "Помоћ" имате детаљно објашњену сваку могућност која се тиче избора одељења.',
          }
        ]
      }
      return []
    } 
  },
  methods: {
    getHelp() {
      if (this.$route.name == 'web' || this.$route.name == 'profile' || this.$route.name == 'applications' || this.$route.name == 'devices' || this.$route.name == 'connection' || this.$route.name == 'teacher_classes') {
        this.$tours.vuexyTour.start();
      }
    },
  },
  watch: {
    '$route.name': function() {
      this.$tours.vuexyTour.stop();
    }
  }
}
</script>

<style lang="scss">
  .app-help {
    color: #305aef;
  }
  .app-help:hover {
    cursor: pointer !important;
    color: #00cfe8;
  }
  @import '@core/scss/vue/libs/tour.scss';
</style>