import baseUrl from '../baseUrl.js'
import axios from "axios";
import errorHandler from '../errorHandler';

const initialState = () => ({
  qr_code: null,
  class_name: ''
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    qr_code: state => {
      return state.qr_code;
    },
    class_name: state => {
      return state.class_name;
    }
  },
  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key]
      });
    },
    SET_QR_CODE(state, value) {
      state.class_name = value.class_name;
      delete value.class_name;
      state.qr_code = value;
    },
  },
  actions: {
    getQRcode({rootState, commit}, payload) {
      axios.get(baseUrl + 'connection/getQRcode/' + rootState.auth.device_group, rootState.auth.token).then(
        res => {
          commit('SET_QR_CODE', res.data);
        }
      ).catch(
        err => {
          errorHandler(payload.toast, err);
        }
      );
    },
  },
}
