<template>
  <div v-if="devices">
    <b-card title="Листа свих таблет уређаја у вашем одељењу">
      <b-row class="mb-2">
        <b-col cols="12" md="4" lg="4" xl="4">
          <b-input-group id="filter-devices" size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Филтрирај" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Обриши
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      
      <div id="device-table">
        <b-table :items="devices" :fields="fields" :filter="filter" responsive outlined small>
        <template #cell(num)="data">
          <div>{{ data.index + 1 }}</div>
        </template>

        <template #cell(uid)="data">
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(group_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(school_name)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(address)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(municipality)="data" >
          <div class="nowrap-table-cell">{{ data.value }}</div>
        </template>

        <template #cell(app)="row">
          <div class="nowrap-table-cell">
            <b-button variant="outline-primary" class="custom-small-padding" @click="viewApps(row.item.id, row.item.uid)">
              Преглед
            </b-button>
          </div>
        </template>

        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable, BInputGroup, BFormInput, BInputGroupAppend, BButton, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol
  },
  mounted() {
    this.$store.dispatch('devices/getAllDevices', {teacher_id: this.account_id, toast: this.$bvToast});
  },
  data() {
    return {
      fields: [
        { key: 'num', label: '#', sortable: false},
        { key: 'uid', label: 'Код уређаја', sortable: true},
        { key: 'group_name', label: 'Одељење', sortable: false},
        { key: 'school_name', label: 'Школа', sortable: false},
        { key: 'address', label: 'Адреса', sortable: false},
        { key: 'municipality', label: 'Општина', sortable: false},
        { key: 'app', label: 'Детаљи', sortable: false }
      ],
      filter: null,
    }
  },
  computed: {
    devices() {
      return this.$store.getters['devices/devices'];
    }
  },
  methods: {
    viewApps(id, uid) {
      this.$store.commit('applications/SET_DEVICE_FOR_APPS', {id: id, name: uid});
      this.$router.push({path: '/device_applications'});
    }
  },
  destroyed() {
    this.$store.commit('devices/SET_DEVICES', {devices: []});
  }
}
</script>

<style lang="scss">
  
</style>
